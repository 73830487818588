:root {
    /* COLORS */
    /* Decrement Button */
    --decrement-btn-bg: #e74c3c;
    --decrement-btn-sh-1: darkred;
    --decrement-btn-sh-2: #c0392b;

    /* Increment Button */
    --increment-btn-bg: #3498db;
    --increment-btn-sh-1: #2c3e50;
    --increment-btn-sh-2: #2980b9;

    /* Reset Button */
    --reset-btn-bg: #7f8c8d;

    /* other colors */
    --shadow-black: rgba(0, 0, 0, 0.2);
    --button-text: white;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}